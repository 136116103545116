import React, { useState } from "react";
import { paths } from "../../common/constants/Rutas";
import useLocalStorage from "../../hooks/useLocalStorage";

export const MenuBar = () => {
  const { get } = useLocalStorage();
  const opciones = [
    { id: 1, icono: "inicio", ruta: paths.inicio, titulo: "Inicio" },
    { id: 2, icono: "sorteos", ruta: paths.sorteos, titulo: "Sorteos" },
    { id: 3, icono: "boletos", ruta: paths.boletos, titulo: "Boletos" },
    { id: 4, icono: "colaboradores", ruta: paths.colaboradores, titulo: "Colaboradores" },
    { id: 5, icono: "contacto", ruta: paths.contacto, titulo: "Contacto" },
    { id: 6, icono: "usuario", ruta: paths.miCuenta, titulo: "Mi cuenta", requireLogin: true },
  ]
  const [showOptions, setShowOptions] = useState(false);
  const hash = document.location?.hash.substring(0, (document.location?.hash.indexOf('?') > 0) ? document.location?.hash.indexOf('?') : document.location?.hash.length);
  const pagina = opciones.find(opcion => opcion?.ruta === hash) || opciones[0];

  const onClick = (opcion) => {
    document.location = opcion.ruta;
    document.title = `${opcion.titulo} - La del pesito`;
    setShowOptions(false);
  }

  return (
    <div className={"menuBar"}>
      {
        opciones.filter(opcion => (!opcion.requireLogin || get('_MiCuenta_token', true))).map(opcion =>
          <button
            key={opcion.id}
            className="menuBar-opcion"
            onClick={() => onClick(opcion)}
          >
            <div className={`menuBar-icon icon-${opcion.icono}`} />
          </button>
        )
      }
    </div>
  );
};

export default MenuBar;
