import React, { useState } from "react";
import QrReader from 'react-qr-scanner'

export const QrScanner = ({
  enabled,
  onScan
}) => {
  const onScanValue = (e) => {
    if (e?.text && onScan) {
        onScan(e);
    }
  }

  const onError = (e) => {
    if (e?.text) {
      console.log('onError e:', e);
    }
  }

  return (
    <>
      { enabled && 
        <div className="qr-modal">
          <div className="qr-camera">
            <div className="qr-frame"></div>
             {/*<div className="qr-frame-top"></div>
            <div className="qr-frame-left"></div>
            <div className="qr-frame-bottom"></div>
            <div className="qr-frame-right"></div>*/}
            <QrReader
              delay={500}
              style={{
                height: 200,
                width: 200,
              }}
              facingMode={"rear"}
              onError={(e) => {
                if (e && onError) {
                  console.log('onError a.text', e?.text);
                  onError(e);
                }
              }}
              onScan={onScanValue}
            />
          </div>
        </div>
      }
    </>
  );
};

export default QrScanner;
