import React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "../../components/app-bar";
import MenuBar from "../../components/menu-bar";

export default function HomeLayout(props) {

  return (
    <>
      <Outlet/>
      <AppBar />
      <MenuBar />
    </>
  );
}
